exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ambassador-js": () => import("./../../../src/pages/ambassador.js" /* webpackChunkName: "component---src-pages-ambassador-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-faqs-general-js": () => import("./../../../src/pages/faqs/general.js" /* webpackChunkName: "component---src-pages-faqs-general-js" */),
  "component---src-pages-faqs-index-js": () => import("./../../../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-faqs-payment-js": () => import("./../../../src/pages/faqs/payment.js" /* webpackChunkName: "component---src-pages-faqs-payment-js" */),
  "component---src-pages-faqs-products-js": () => import("./../../../src/pages/faqs/products.js" /* webpackChunkName: "component---src-pages-faqs-products-js" */),
  "component---src-pages-faqs-rentals-js": () => import("./../../../src/pages/faqs/rentals.js" /* webpackChunkName: "component---src-pages-faqs-rentals-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-pay-in-installments-js": () => import("./../../../src/pages/pay-in-installments.js" /* webpackChunkName: "component---src-pages-pay-in-installments-js" */),
  "component---src-pages-privacy-notice-js": () => import("./../../../src/pages/privacy-notice.js" /* webpackChunkName: "component---src-pages-privacy-notice-js" */),
  "component---src-pages-reeddi-bigenergy-js": () => import("./../../../src/pages/reeddi-bigenergy.js" /* webpackChunkName: "component---src-pages-reeddi-bigenergy-js" */),
  "component---src-pages-reeddi-capsule-js": () => import("./../../../src/pages/reeddi-capsule.js" /* webpackChunkName: "component---src-pages-reeddi-capsule-js" */),
  "component---src-pages-reeddi-energybox-js": () => import("./../../../src/pages/reeddi-energybox.js" /* webpackChunkName: "component---src-pages-reeddi-energybox-js" */),
  "component---src-pages-rent-a-capsule-js": () => import("./../../../src/pages/rent-a-capsule.js" /* webpackChunkName: "component---src-pages-rent-a-capsule-js" */),
  "component---src-pages-rental-policy-js": () => import("./../../../src/pages/rental-policy.js" /* webpackChunkName: "component---src-pages-rental-policy-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

